<template>
	<div style="background: #fff;padding: 15px;">
		<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;padding-bottom: 50px;">
			<el-form :model="ruleForm" ref="ruleForm" label-width="180px" class="demo-ruleForm" :validate-on-rule-change="false">
				<el-form-item label="供货商名称:" prop="supplierName" :rules="[{required:true,message:'供货商名称不能为空'}]">
					<el-input v-model="ruleForm.supplierName" placeholder="请输入供货商名称" maxlength="20" clearable style="width:350px"></el-input>
				</el-form-item>
				<el-form-item label="联系人名称:" prop="contactName" :rules="[{required:true,message:'联系人名称不能为空'}]">
					<el-input v-model="ruleForm.contactName" placeholder="请输入联系人名称" maxlength="20" clearable style="width:350px"></el-input>
				</el-form-item>
				<el-form-item label="联系人电话:" prop="contactPhone" :rules="[{required:true,message:'联系人电话不能为空'},{ min: 11, max: 11, message: '请输入11位手机号', trigger: 'blur' }]">
					<el-input v-model="ruleForm.contactPhone" @input="formatParseInt(ruleForm,'contactPhone')" placeholder="请输入11位手机号" maxlength="11" clearable style="width:350px"></el-input>
				</el-form-item>
				<el-form-item label="对接人:" prop="dockPeople">
					<el-input v-model="ruleForm.dockPeople" placeholder="请输入对接人" maxlength="20" clearable style="width:350px"></el-input>
				</el-form-item>
				<el-form-item label="仓库id:" prop="extendId" :rules="[{required:true,message:'仓库id不能为空'}]">
					<el-input v-model="ruleForm.extendId" placeholder="请输入仓库id" maxlength="30" clearable style="width:350px"></el-input>
				</el-form-item>
				<el-form-item label="联系人地址:" prop="contactAddress" :rules="[{required:true,message:'联系人地址不能为空'}]">
					<el-input v-model="ruleForm.contactAddress" placeholder="请输入联系人地址" maxlength="50" clearable style="width:350px"
					type="textarea"></el-input>
				</el-form-item>
				<el-form-item label="供货商描述:" prop="supplierDesc">
					<el-input v-model="ruleForm.supplierDesc" placeholder="请输入供货商描述" maxlength="100" clearable style="width:350px"
					type="textarea"></el-input>
				</el-form-item>
				<el-form-item label="上传营业执照:" prop="businessLicenseImg" :rules="[{required:true,message:'请上传营业执照'}]">
					<!-- <div class="filter-item" style="width:100px;text-align: right;vertical-align: top;"><span class="tablered">*</span>商品图片</div> -->
					<div >
						<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess"
						:before-upload="beforeAvatarUpload">
							<img v-if="ruleForm.businessLicenseImg" :src="ruleForm.businessLicenseImg+'?x-oss-process=image/resize,m_fixed,h_180,w_180'"
							class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<div style="color: #007AFF;" @click="clearChose(1)">删除选择</div>
					</div>
				</el-form-item>
				<el-form-item label="上传食品经营许可证:" prop="foodBusinessLicenseImg" >
					<!-- <div class="filter-item" style="width:100px;text-align: right;vertical-align: top;"><span class="tablered">*</span>商品图片</div> -->
					<div>
						<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleFoodSuccess"
						:before-upload="beforeAvatarUpload">
							<img v-if="ruleForm.foodBusinessLicenseImg" :src="ruleForm.foodBusinessLicenseImg+'?x-oss-process=image/resize,m_fixed,h_180,w_180'"
							class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<div style="color: #007AFF;" @click="clearChose(2)">删除选择</div>
					</div>
				</el-form-item>
			</el-form>
		</div>

		<!-- 按钮区域 -->
		<div class="bottom-save-btn">
			<el-button style="margin-right:30px;width: 120px;" @click="backForword()">关闭</el-button>
			<el-button type="primary" style="width: 120px;" @click="saveChange()">保存</el-button>
		</div>
	</div>
</template>

<script>
	import {
		supplierSave
	} from '@/api/goods'
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				ruleForm: {
					supplierName: '',
					contactName: '',
					contactPhone: '',
					dockPeople:'',//对接人
					extendId: '',
					contactAddress: '',
					supplierDesc: '',
					foodBusinessLicenseImg: '',
					businessLicenseImg: '',
					id: 0
				},
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				supplierInfo: null
			};
		},
		created() {
			var info = JSON.parse(this.$route.query.supplierInfo);
			this.supplierInfo = info;
			if (this.supplierInfo) {
				this.ruleForm.supplierName = this.supplierInfo.supplierName;
				this.ruleForm.contactName = this.supplierInfo.contactName;
				this.ruleForm.contactPhone = this.supplierInfo.contactPhone;
				this.ruleForm.dockPeople = this.supplierInfo.dockPeople;
				this.ruleForm.extendId = this.supplierInfo.extendId;
				this.ruleForm.contactAddress = this.supplierInfo.contactAddress;
				this.ruleForm.supplierDesc = this.supplierInfo.supplierDesc;
				this.ruleForm.foodBusinessLicenseImg = this.supplierInfo.foodBusinessLicenseImg || '';
				this.ruleForm.businessLicenseImg = this.supplierInfo.businessLicenseImg || '';
				this.ruleForm.id = this.supplierInfo.id;
			}
		},
		methods: {
			async saveData() {
				var _this = this;
				try {
					let data = {
						contactAddress: this.ruleForm.contactAddress,
						contactName: this.ruleForm.contactName,
						contactPhone: this.ruleForm.contactPhone,
						dockPeople:this.ruleForm.dockPeople,
						extendId: this.ruleForm.extendId,
						supplierDesc: this.ruleForm.supplierDesc,
						supplierName: this.ruleForm.supplierName,
						foodBusinessLicenseImg:this.ruleForm.foodBusinessLicenseImg,
						businessLicenseImg:this.ruleForm.businessLicenseImg,
						id: this.ruleForm.id ? this.ruleForm.id : '',
					};
					let res = await supplierSave(data);
					if (res.success) {
						this.supplyVisable = false;
						var str = this.ruleForm.id ? "供货商编辑成功" : '供货商添加成功';
						this.$message({
							showClose: true,
							type: 'success',
							message: str
						});
						setTimeout(function(){
							_this.backForword();
						},2000)
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg
						});
					}
				} catch (e) {
					//TODO handle the exception
					console.log(e);
				}
			},
			saveChange() {
				this.$refs['ruleForm'].validate(async (valid) => {
					if (valid) {
						try {
							this.saveData();
						} catch (e) {
							//TODO handle the exception
						}
					}
				});
			},
			backForword() {
				this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
					(item) => {
						return item.path != this.$route.path;
					}
				);
				this.$router.push({
					path: '/goods/supplierManager'
				});
			},
			handleAvatarSuccess(res) {
				this.ruleForm.businessLicenseImg = res.data[0];
			},
			handleFoodSuccess(res) {
				this.ruleForm.foodBusinessLicenseImg = res.data[0];
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt3M = file.size / 1024 / 1024 < 3;
				if (!isJPG) {
					this.$message.error('上传头像图片必须是 JPG 或者PNG格式!');
				}
				if (!isLt3M) {
					this.$message.error('图片大小请控制在3M以内!');
				}

				//获取地址
				// let temp = util.uploadFile(1, 0, file);
				// console.log(temp);
				// return false;
				return isJPG && isLt3M;
			},
			clearChose(type){
				if(type==1){
					this.ruleForm.businessLicenseImg = "";
				}else{
					this.ruleForm.foodBusinessLicenseImg = "";
				}
            },
		}
	}
</script>

<style lang="less" scoped>
	.exportBtn {
		position: relative;
	}

	.exportInput {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 90px;
	}

	#inputsId {
		display: inline-block;
		height: 35px;
		width: 90px;
	}

	.footer {
		position: fixed;
		width: 100%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		box-shadow: 13px 1px 6px #999;
	}

	.dialog-name-content {
		margin: 10px 0;
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			margin-right: 10px;
		}
	}

	.avatar-uploader .el-upload {
		// border: 1px dashed #d9d9d9;
		// border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		font-size: 28px;
		color: #8c939d;
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
	}

	.avatar {
		width: 120px;
		height: 120px;
		display: block;
	}

	.bottom-save-btn {
		position: fixed;
		width: 100%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		box-shadow: 13px 1px 6px #999;
	}
</style>
